import React, { useState, useEffect, useContext } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import ToastError from "../../shared/elements/ToastError";
import { Spinner } from "react-bootstrap";

const VersionInfo = () => {
	const [versionData, setVersionData] = useState(null);
	const { isLoading, error, sendRequest, clearError } = useHttpClient();
	const auth = useContext(AuthContext);

	useEffect(() => {
		const fetchLatestVersion = async () => {
			try {
				const response = await sendRequest("/api/version", "GET", null, {
					Authorization: "Bearer " + auth.token,
				});
				setVersionData(response.data);
			} catch (error) {
				console.error("Error fetching version data:", error);
			}
		};

			fetchLatestVersion();
		
	}, [sendRequest, auth.token]);

	if (!versionData) {
		return null;
	}

	return (
		<React.Fragment>
            <ToastError show={!!error} message={error} onClose={clearError} />
			{isLoading && <Spinner animation="border" />}
			{versionData && (
				<div style={{ color: "rgba(255, 255, 255, 0.7)"}}>
					<p>
						v{versionData}
					</p>
				</div>
			)}
		</React.Fragment>
	);
};

export default VersionInfo;
