import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import {
	Button,
	Modal,
	ListGroup,
	Spinner,
	ToggleButtonGroup,
	ToggleButton,
	Offcanvas,
	Toast,
	Form,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ToastError from "../../shared/elements/ToastError";
import VideoCard from "./VideoCard";
import ReactPlayer from "react-player";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
	FaList,
	FaTh,
	FaDownload /*FaShareAlt, FaFacebook, FaLinkedin, FaInstagram*/,
} from "react-icons/fa";

const truncateText = (text, maxLength) => {
	if (text.length <= maxLength) {
		return text;
	}
	return text.substring(0, maxLength) + "...";
};

const VideoList = () => {
	const authContext = useContext(AuthContext);
	const { isLoading, error, sendRequest, clearError } = useHttpClient();
	const [videos, setVideos] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const [view, setView] = useState("cards");
	//const [showShareOptions, setShowShareOptions] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [showOffcanvas, setShowOffcanvas] = useState(false);
	const handleClose = () => setShowOffcanvas(false);
	const handleShow = () => setShowOffcanvas(true);

	/*const handleShareClick = () => {
        setShowShareOptions(!showShareOptions);
    };*/

	const renderStatus = (status, videoUrl) => {
		let statusElement;

		if (status === "pending") {
			statusElement = (
				<Spinner animation="border" size="sm" variant="warning" />
			);
		} else if (status === "processing") {
			statusElement = (
				<Spinner animation="border" size="sm" variant="success" />
			);
		} else if (status === "completed") {
			statusElement = (
				<div style={{ display: "flex", alignItems: "center" }}>
					<Button
						variant="link"
						style={{ padding: "5px", borderRadius: "50%" }}
						href={videoUrl}
						target="_blank"
						rel="noopener noreferrer"
						download
					>
						<FaDownload />
					</Button>
				</div>
			);
		} else if (status === "failed") {
			statusElement = (
				<span
					style={{
						display: "inline-block",
						width: "10px",
						height: "10px",
						backgroundColor: "red",
						borderRadius: "50%",
					}}
				></span>
			);
		}

		return (
			<OverlayTrigger
				placement="top"
				delay={{ show: 250, hide: 400 }}
				overlay={
					<Tooltip>{status === "completed" ? "Download" : status}</Tooltip>
				}
			>
				<div style={{ display: "inline-block" }}>{statusElement}</div>
			</OverlayTrigger>
		);
	};

	const navigate = useNavigate();

	const handleToggleShared = async () => {
		if (!selectedVideo) return;
		try {
			const response = await sendRequest(
				`/api/videos/${selectedVideo.id}/toggle-shared`,
				"POST",
				null,
				{
					Authorization: "Bearer " + authContext.token,
					"Content-Type": "application/json",
				}
			);
			const updatedSharedStatus = response.data.shared;

			setSelectedVideo((prev) => ({
				...prev,
				shared: updatedSharedStatus,
			}));
		} catch (err) {
			console.error("Error toggling video shared status:", err);
		}
	};

	const handleToggleVideo = async () => {
		if (!selectedVideo) return;
		try {
			const response = await sendRequest(
				`/api/videos/${selectedVideo.id}/toggle-public`,
				"POST",
				null,
				{
					Authorization: "Bearer " + authContext.token,
					"Content-Type": "application/json",
				}
			);
			const updatedPublicStatus = response.data.public;

			setSelectedVideo((prev) => ({
				...prev,
				public: updatedPublicStatus,
			}));
			//fetchVideos(); // Refresh the video list after finalization
		} catch (err) {
			console.error("Error finalizing video:", err);
		}
	};

	const handleDeleteVideo = async () => {
		if (!selectedVideo) return;
		try {
			await sendRequest(
				`/api/videos/${selectedVideo.id}`,
				"DELETE",
				JSON.stringify({ orgName: selectedVideo.orgName }),
				{
					Authorization: "Bearer " + authContext.token,
					"Content-Type": "application/json",
				}
			);
			setShowModal(false);
			fetchVideos(); // Refresh the video list after finalization
		} catch (err) {
			console.error("Error deleting video:", err);
		}
	};

	const fetchVideos = useCallback(async () => {
		try {
			const response = await sendRequest("/api/videos", "GET", null, {
				Authorization: "Bearer " + authContext.token,
			});
			const responseData = response.data;
			if (Array.isArray(responseData)) {
				setVideos(responseData);
			} else {
				setVideos([]);
			}
		} catch (err) {
			console.error("Error fetching videos:", err);
		}
	}, [sendRequest, authContext.token]);

	useEffect(() => {
		fetchVideos();
	}, [fetchVideos]);

	const handleShowVideo = async (video) => {
		try {
			const response = await sendRequest(
				`/api/videos/${video.id}/details`,
				"GET",
				null,
				{
					Authorization: "Bearer " + authContext.token,
				}
			);
			setSelectedVideo(response.data);
			setShowModal(true);
		} catch (err) {
			console.error("Error fetching video details:", err);
		}
	};

	/*const handleEditVideo = () => {
		if (selectedVideo) {
			navigate(`/videos/edit/${selectedVideo.id}`);
		}
	};*/

	const handleCloseModal = () => {
		setShowModal(false);
		setSelectedVideo(null);
	};

	const handleViewChange = (val) => setView(val);

	return (
		<div data-tour-target="studio-content">
			<ToastError show={!!error} message={error} onClose={clearError} />
			<Button
				variant="primary"
				onClick={() => navigate("/videos/new")}
				style={{ marginBottom: "20px", marginRight: "20px" }}
			>
				Add Video
			</Button>
			<ToggleButtonGroup
				type="radio"
				name="view"
				value={view}
				onChange={handleViewChange}
				style={{ marginBottom: "20px" }}
			>
				<ToggleButton id="tbg-btn-1" value={"list"}>
					<FaList />
				</ToggleButton>
				<ToggleButton id="tbg-btn-2" value={"cards"}>
					<FaTh />
				</ToggleButton>
			</ToggleButtonGroup>
			<Button
				variant="light"
				style={{ marginLeft: "20px", marginBottom: "20px" }}
				onClick={handleShow}
			>
				Important Note!
			</Button>
			{isLoading && <Spinner animation="border" />}
			{view === "list" ? (
				<ListGroup>
					{videos.length === 0 && (
						<ListGroup.Item>No videos found.</ListGroup.Item>
					)}
					{videos.map((video) => (
						<ListGroup.Item
							key={video.id}
							onClick={() => handleShowVideo(video)}
						>
							{video.title}
						</ListGroup.Item>
					))}
				</ListGroup>
			) : (
				<div style={{ display: "flex", flexWrap: "wrap" }}>
					{videos.length === 0 && <p>No videos found.</p>}
					{videos.map((video) => (
						<VideoCard
							key={video.id}
							video={video}
							onShowVideo={handleShowVideo}
						/>
					))}
				</div>
			)}
			{selectedVideo && (
				<Modal show={showModal} onHide={handleCloseModal}>
					<Modal.Header closeButton>
						<Modal.Title>
							{selectedVideo ? selectedVideo.title : "Video Details"}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{selectedVideo && (
							<div>
								{selectedVideo.video_url ? (
									<ReactPlayer
										url={selectedVideo.video_url}
										controls={true}
										width="100%"
									/>
								) : (
									<img
										src={selectedVideo.thumbnail_url}
										width="100%"
										alt={selectedVideo.avatar.avatar_name}
									/>
								)}
								<p
									className="mb-3"
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									{renderStatus(selectedVideo.status, selectedVideo.video_url)}
									{/*add lock icon if selectedVideo.public is false or nonexistent */}
									{!selectedVideo.public ? (
										<OverlayTrigger
											placement="top"
											delay={{ show: 250, hide: 400 }}
											overlay={<Tooltip>Private</Tooltip>}
										>
											<i
												className="fas fa-lock"
												style={{ marginLeft: "10px" }}
											/>
										</OverlayTrigger>
									) : (
										<OverlayTrigger
											placement="top"
											delay={{ show: 250, hide: 400 }}
											overlay={<Tooltip>Public</Tooltip>}
										>
											<i
												className="fas fa-unlock"
												style={{ marginLeft: "10px" }}
											/>
										</OverlayTrigger>
									)}
								</p>

								{selectedVideo.script && (
									<div>
										<p>
											<strong>Script:</strong> {selectedVideo.script.title}
										</p>
										<p>
											<strong>Script Content:</strong>{" "}
											{truncateText(selectedVideo.script.content, 100)}
										</p>
									</div>
								)}
								<p>
									<strong>Avatar:</strong> {selectedVideo.avatar.avatar_name}
								</p>
								{selectedVideo.campaign && (
									<p>
										<strong>Campaign:</strong> {selectedVideo.campaign.name}
									</p>
								)}
								<p>
									<strong>Created By: </strong> {selectedVideo.createdBy}
								</p>
								{(authContext.isSuperAdmin ||
									authContext.orgRole === "orgAdmin" ||
									selectedVideo.userId === authContext.userId) && (
									<React.Fragment>
										<OverlayTrigger
											placement="top"
											delay={{ show: 250, hide: 400 }}
											overlay={
												<Tooltip>
													{selectedVideo.shared
														? "Make your video private"
														: "Share your video with your organization!"}
												</Tooltip>
											}
										>
											<Form.Check
												type="switch"
												id="share-switch"
												label="Share internally with organization"
												checked={selectedVideo.shared}
												onChange={handleToggleShared}
											/>
										</OverlayTrigger>
										<OverlayTrigger
											placement="top"
											delay={{ show: 250, hide: 400 }}
											overlay={
												<Tooltip>
													{selectedVideo.public
														? "Make your video private"
														: "Share your video publicly!"}
												</Tooltip>
											}
										>
											<Form.Check
												type="switch"
												id="share-switch"
												label="Share public video link"
												checked={selectedVideo.public}
												onChange={handleToggleVideo}
											/>
										</OverlayTrigger>
									</React.Fragment>
								)}
							</div>
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseModal}>
							Close
						</Button>
						{(authContext.isSuperAdmin ||
							authContext.orgRole === "orgAdmin" ||
							//if the user is the creator of the video
							selectedVideo.userId === authContext.userId) && (
							<React.Fragment>
								{selectedVideo.public && (
									<OverlayTrigger
										placement="top"
										delay={{ show: 150, hide: 200 }}
										overlay={<Tooltip>Share video link with public</Tooltip>}
									>
										<Button
											variant="primary"
											onClick={() => {
												navigator.clipboard.writeText( //`${window.location.origin}/meta-preview/${selectedVideo.id}`
													`${window.location.origin}/share?id=${selectedVideo.id}`
												);
												setShowToast(true); // Show the toast
											}}
										>
											Share Public Link
										</Button>
									</OverlayTrigger>
								)}
								{selectedVideo.shared && (
									<OverlayTrigger
										placement="top"
										delay={{ show: 150, hide: 200 }}
										overlay={
											<Tooltip>Share video link with organization</Tooltip>
										}
									>
										<Button
											variant="primary"
											onClick={() => {
												navigator.clipboard.writeText(
													`${window.location.origin}/video?id=${selectedVideo.id}`
												);
												setShowToast(true); // Show the toast
											}}
										>
											Share Internal Link
										</Button>
									</OverlayTrigger>
								)}
								{/*display if selectedvideo.public is true */}

								<Button variant="danger" onClick={() => handleDeleteVideo()}>
									Delete Video
								</Button>
							</React.Fragment>
						)}
					</Modal.Footer>
				</Modal>
			)}
			<Offcanvas show={showOffcanvas} onHide={handleClose} placement={"end"}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>
						<span class="icon">👋</span>Hello!
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<p>
						Please note that generating videos will take some time. Make sure to
						refresh the page to see the status of your video.
					</p>
					<p>
						Due to how videos are made with the API, we recommend them for
						internal use only. If you would like to make a video for commercial
						purposes, like advertising or social media, contact us!
					</p>
					<p>
						<strong>
							Please contact Max or Kaleb to to create a professional video for
							you!
						</strong>
					</p>
					<p>
						<ul>
							<strong>Max: </strong>
							<a href="mailto:maximus@ratava.com">maximus@ratava.com</a>
						</ul>
						<ul>
							<strong>Kaleb: </strong>
							<a href="mailto:kaleb@ratava.com">kaleb@ratava.com</a>
						</ul>
					</p>
				</Offcanvas.Body>
			</Offcanvas>
			<Toast
				onClose={() => setShowToast(false)}
				show={showToast}
				delay={6000}
				autohide
				style={{ position: "fixed", top: 20, right: 20, zIndex: 9999 }}
			>
				<Toast.Header>Link copied to clipboard!</Toast.Header>
			</Toast>
		</div>
	);
};

export default VideoList;
