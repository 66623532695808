import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import ToastError from "../../shared/elements/ToastError";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import {
	Button,
	Row,
	Form,
	Spinner,
	Tooltip,
	OverlayTrigger,
} from "react-bootstrap";
import * as Yup from "yup";
import AvatarSelection from "./AvatarSelection";
import EntityDropdown from "./EntityDropdown"; // Ensure this is imported
import MicRecorder from "mic-recorder-to-mp3";
import { FaMicrophone, FaStopCircle } from "react-icons/fa";

const videoSchema = Yup.object().shape({
	title: Yup.string().required("Title is required"),
	avatar: Yup.string().required("Avatar is required"),
	look: Yup.string().required("A look must be selected"),
	orgId: Yup.string().required("Organization is required"),
});

const VideoEditor = ({ onVideoUpdated = () => {} }) => {
	const authContext = useContext(AuthContext);
	const { isLoading, error, sendRequest, clearError } = useHttpClient();
	const { videoId } = useParams();
	const navigate = useNavigate();
	const [userOrganizations, setUserOrganizations] = useState([]);
	const [selectedOrgId, setSelectedOrgId] = useState("");
	const [showOrganizationDropdown, setShowOrganizationDropdown] =
		useState(false);
	const [initialValues, setInitialValues] = useState(null);

	const [videoData, setVideoData] = useState(null);
	const [organizationsLoaded, setOrganizationsLoaded] = useState(false);
	const [useRecording, setUseRecording] = useState(false);
	const [audioBlob, setAudioBlob] = useState(null);
	const [audioURL, setAudioURL] = useState(null);

	const [isRecording, setIsRecording] = useState(false);
	const [recorder] = useState(() => new MicRecorder({ bitRate: 128 }));

	const startRecording = async () => {
		try {
			await recorder.start();
			setIsRecording(true);
		} catch (err) {
			console.error(err);
		}
	};

	const resetRecording = () => {
		setAudioBlob(null);
		setAudioURL(null);
	};

	const stopRecording = async () => {
		try {
			const [, blob] = await recorder.stop().getMp3();
			const audioUrl = URL.createObjectURL(blob);
			setAudioBlob(blob);
			setAudioURL(audioUrl);
			setIsRecording(false);
		} catch (err) {
			console.error("Failed to stop recording:", err);
		}
	};

	useEffect(() => {
		const fetchVideo = async () => {
			if (videoId) {
				try {
					const response = await sendRequest(
						`/api/videos/${videoId}`,
						"GET",
						null,
						{
							Authorization: "Bearer " + authContext.token,
						}
					);
					const video = response.data;
					setVideoData({
						title: video.title,
						script: video.script.id,
						avatar: video.avatar.id,
						orgId: video.orgId || "",
					});
				} catch (err) {
					console.error(err);
				}
			} else {
				setVideoData({
					title: "",
					script: "",
					avatar: "",
					orgId: "",
				});
			}
		};

		const fetchUserOrganizations = async () => {
			try {
				let organizations = [];

				if (authContext.isSuperAdmin) {
					const response = await sendRequest(
						"/api/organizations",
						"GET",
						null,
						{
							Authorization: "Bearer " + authContext.token,
						}
					);
					organizations = response.data;
				} else {
					organizations = authContext.memberships;
				}

				setUserOrganizations(organizations);

				const showDropdown =
					authContext.isSuperAdmin || organizations.length > 1;
				setShowOrganizationDropdown(showDropdown);
				setOrganizationsLoaded(true);
			} catch (err) {
				console.error("Error fetching organizations:", err);
			}
		};

		fetchVideo();
		fetchUserOrganizations();
	}, [
		sendRequest,
		authContext.token,
		videoId,
		authContext.isSuperAdmin,
		authContext.memberships,
	]);

	useEffect(() => {
		if (videoData && organizationsLoaded) {
			let orgId = videoData.orgId;

			if (showOrganizationDropdown) {
				orgId = orgId || userOrganizations[0]?.orgId || "";
			} else if (userOrganizations.length === 1) {
				orgId = userOrganizations[0].orgId;
			} else {
				console.error("No organizations available for the user.");
			}

			setSelectedOrgId(orgId);

			setInitialValues({
				...videoData,
				orgId,
				audio: "",
			});
		}
	}, [
		videoData,
		organizationsLoaded,
		showOrganizationDropdown,
		userOrganizations,
	]);

	const uploadAudio = async (audioBlob) => {
		const formData = new FormData();
		formData.append("audio", audioBlob, "audio.mp3");

		try {
			console.log("Uploading audio...");
			const response = await sendRequest(
				"/api/audio/upload",
				"POST",
				formData,
				{
					Authorization: "Bearer " + authContext.token,
				}
			);
			return response.data.url;
		} catch (err) {
			console.error(err);
		}
	};

	const submitHandler = async (
		values,
		{ setSubmitting, setFieldError, resetForm }
	) => {
		try {
			if (!useRecording && !values.script) {
				setFieldError("script", "Script is required if not using recording");
				setSubmitting(false);
				return;
			}

			if (useRecording && !audioBlob) {
				setFieldError("audio", "Recording is required");
				setSubmitting(false);
				return;
			}

			let audioUrl;
			if (useRecording && audioBlob) {
				audioUrl = await uploadAudio(audioBlob);
				console.log("using audio URL:", audioUrl);
			}

			const payload = {
				title: values.title,
				avatar: values.avatar,
				look: values.look,
				orgId: values.orgId,
			};

			if (useRecording) {
				payload.audio = audioUrl;
			} else {
				payload.script = values.script;
			}

			if (!payload.orgId) {
				console.error("Organization ID is required");
				return;
			}

			if (videoId) {
				await sendRequest(
					`/api/videos/${videoId}`,
					"PUT",
					JSON.stringify(payload),
					{
						"Content-Type": "application/json",
						Authorization: "Bearer " + authContext.token,
					}
				);
			} else {
				await sendRequest("/api/videos", "POST", JSON.stringify(payload), {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authContext.token,
				});
			}
			onVideoUpdated();
			resetForm();
			navigate("/videos");
		} catch (err) {
			console.error(err.message);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<React.Fragment>
			<ToastError show={!!error} message={error} onClose={clearError} />
			{isLoading && <Spinner animation="border" />}
			{initialValues ? (
				<Formik
					initialValues={initialValues}
					validationSchema={videoSchema}
					onSubmit={submitHandler}
				>
					{(formikProps) => (
						<FormikForm noValidate onSubmit={formikProps.handleSubmit}>
							<Row className="mb-3">
								<Form.Group md="6" controlId="title">
									<Form.Label>Title</Form.Label>
									<Field
										as={Form.Control}
										type="text"
										name="title"
										isInvalid={
											formikProps.touched.title && formikProps.errors.title
										}
									/>
									<ErrorMessage
										name="title"
										component="div"
										className="text-danger"
									/>
								</Form.Group>
							</Row>
							<Row className="mb-3">
								<Form.Check
									type="switch"
									id="use-recording"
									label="Use recording"
									checked={useRecording}
									onChange={(e) => setUseRecording(e.target.checked)}
								/>
							</Row>
							{!useRecording ? (
								<Row className="mb-3">
									<EntityDropdown
										entity="scripts"
										value={formikProps.values.script}
										onChange={(e) =>
											formikProps.setFieldValue("script", e.target.value)
										}
										onBlur={() => formikProps.setFieldTouched("script", true)}
										error={formikProps.errors.script}
										touched={formikProps.touched.script}
									/>
									{(authContext.isSuperAdmin ||
										authContext.orgRole === "orgAdmin") && (
										<Button
											variant="link"
											onClick={() => navigate("/scripts/new")}
										>
											Add New Script
										</Button>
									)}
								</Row>
							) : (
								<Row className="mb-3">
									<div className="d-flex align-items-center">
										{!audioURL && (
											<>
												{isRecording ? (
													<Button
														variant="danger"
														onClick={stopRecording}
														className="d-flex align-items-center"
													>
														<FaStopCircle size={20} className="me-2" />
														Stop
													</Button>
												) : (
													<Button
														variant="primary"
														onClick={startRecording}
														className="d-flex align-items-center"
													>
														<FaMicrophone size={20} className="me-2" />
														Record
													</Button>
												)}
												{isRecording && (
													<div className="d-flex align-items-center ms-3">
														<div className="recording-dot me-2"></div>
														<span className="text-danger fw-bold">
															Recording...
														</span>
													</div>
												)}
												<OverlayTrigger
													placement="top" // Choose tooltip placement: top, right, bottom, left
													overlay={
														<Tooltip id="beta-tooltip">
															This feature is new! Please let us know if you have any issues.
														</Tooltip>
													}
												>
													<span className="beta-badge ms-3">Beta</span>
												</OverlayTrigger>
											</>
										)}

										{audioURL && (
											<div className="d-flex align-items-center">
												<audio
													controls
													className="me-3"
													style={{ maxWidth: "400px" }}
												>
													<source src={audioURL} type="audio/mp3" />
													Your browser does not support the audio element.
												</audio>
												<Button variant="warning" onClick={resetRecording}>
													Re-record
												</Button>
											</div>
										)}

										{formikProps.errors.audio && !audioURL && (
											<div className="text-danger mt-2">
												{formikProps.errors.audio}
											</div>
										)}
									</div>
								</Row>
							)}
							<Row className="mb-3">
								<AvatarSelection
									value={{
										avatarId: formikProps.values.avatar,
										lookId: formikProps.values.lookId,
										thumbnail: formikProps.values.thumbnail,
									}}
									onChange={({ avatarId, lookId, thumbnail }) => {
										formikProps.setFieldValue("avatar", avatarId);
										formikProps.setFieldValue("look", lookId);
										formikProps.setFieldValue("thumbnail", thumbnail);
									}}
									onBlur={() => {
										formikProps.setFieldTouched("avatar", true);
									}}
									error={formikProps.errors.avatar}
									touched={formikProps.touched.avatar}
								/>
								{authContext.isSuperAdmin && (
									<Button
										variant="link"
										onClick={() => navigate("/avatarconfig")}
									>
										Add New Avatar
									</Button>
								)}
							</Row>
							{showOrganizationDropdown && (
								<Row className="mb-3">
									<Form.Group controlId="orgId">
										<Form.Label>Organization</Form.Label>
										<Form.Control
											as="select"
											name="orgId"
											value={formikProps.values.orgId || selectedOrgId}
											onChange={(e) => {
												setSelectedOrgId(e.target.value);
												formikProps.setFieldValue("orgId", e.target.value);
											}}
										>
											<option value="">Select an organization</option>
											{userOrganizations.map((org) => (
												<option key={org.orgId} value={org.orgId}>
													{org.orgName || org.name}
												</option>
											))}
										</Form.Control>
										<ErrorMessage
											name="orgId"
											component="div"
											className="text-danger"
										/>
									</Form.Group>
								</Row>
							)}
							<Button
								className="submit-button"
								variant="primary"
								type="submit"
								disabled={
									formikProps.isSubmitting ||
									!formikProps.dirty ||
									!formikProps.values.orgId
								}
							>
								{videoId ? "Update" : "Create"}
							</Button>
						</FormikForm>
					)}
				</Formik>
			) : (
				<Spinner animation="border" />
			)}
		</React.Fragment>
	);
};

export default VideoEditor;
