import React, { useState, useContext } from "react";
import { auth } from "../../config/firebase";
import { RecaptchaVerifier, PhoneAuthProvider, multiFactor, PhoneMultiFactorGenerator, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { Button, Alert, Form, Spinner, Modal } from "react-bootstrap";
import ToastError from "../../shared/elements/ToastError";
import VerificationCodeInput from "./VerificationCodeInput";
import { AuthContext } from "../../shared/context/auth-context";

const PhoneVerification = () => {
    const authContext = useContext(AuthContext);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [verificationId, setVerificationId] = useState("");
    const [isVerifying, setIsVerifying] = useState(false);
    const [mfaSetupComplete, setMfaSetupComplete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [reauthEmail, setReauthEmail] = useState("");
    const [reauthPassword, setReauthPassword] = useState("");
    const [showReauthForm, setShowReauthForm] = useState(false);

    const reauthenticateUser = async () => {
        try {
            setLoading(true);
            const credential = EmailAuthProvider.credential(reauthEmail, reauthPassword);
            await reauthenticateWithCredential(auth.currentUser, credential);
            console.log("Reauthentication successful.");
            setShowReauthForm(false);
            
            await startMfaSetup();
        } catch (error) {
            console.error("Error during reauthentication:", error);
            setErrorMessage("Failed to reauthenticate. Please try again or logout and log back in.");
        }
    };

    const setupRecaptcha = () => {
        if (process.env.REACT_APP_ENV === "development") {
            auth.settings.appVerificationDisabledForTesting = true;
            console.log("App verification disabled for testing.");
        }
        
        try {
            if (window.recaptchaVerifier) {
                window.recaptchaVerifier.clear();
                delete window.recaptchaVerifier;

                const recaptchaContainer = document.getElementById("recaptcha-container");
                if(recaptchaContainer) {
                    recaptchaContainer.innerHTML = "";
                }
            }
           
            window.recaptchaVerifier = new RecaptchaVerifier(
                auth,
                "recaptcha-container",
                { size: "invisible", callback: () => console.log("Recaptcha solved") },
            );
        } catch (error) {
            console.error("Error initializing RecaptchaVerifier:", error);
            throw error;
        }
    };

    const startMfaSetup = async () => {
        try {
            setupRecaptcha();

            
            const appVerifier = window.recaptchaVerifier;
            
            const phoneAuthProvider = new PhoneAuthProvider(auth);
            
            setLoading(true);

            const multiFactorSession = await multiFactor(auth.currentUser).getSession();

            const phoneInfoOptions = {
                phoneNumber: phoneNumber,
                session: multiFactorSession
            }

            const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, appVerifier);
            setVerificationId(verificationId);
            setLoading(false);
        } catch (error) {
            console.error("Error during MFA setup:", error);
            if (error.code === 'auth/requires-recent-login') {
                setErrorMessage("Please reauthenticate to enable MFA.");
                setShowReauthForm(true);
            } else {
                setErrorMessage("Failed to send verification code. Please try again.");
            }
            setLoading(false);
        } finally {
            console.groupEnd();
        }
    };

    const verifyCode = async () => {
        if (!verificationId || !verificationCode) {
            console.error("Verification ID or code is missing. Aborting.");
            return;
        }

        try {
            setIsVerifying(true);
            const credential = PhoneAuthProvider.credential(verificationId, verificationCode);

            const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential);

            await multiFactor(auth.currentUser).enroll(multiFactorAssertion, "Phone Number");
            setMfaSetupComplete(true);

            await authContext.refreshUserData();
        } catch (error) {
            console.error("Error during MFA verification:", error);
            setErrorMessage("Invalid verification code. Please try again.");
        } finally {
            setIsVerifying(false);
        }
    };

    return (
        <div>
            <ToastError show={!!errorMessage} message={errorMessage} onClose={() => setErrorMessage("")} />
            <div className="mt-4">
                <h4>Multi-Factor Authentication</h4>
                {mfaSetupComplete ? (
                    <Alert variant="success">SMS Multi-Factor Authentication is enabled.</Alert>
                ) : (
                    <>
                        <Form>
                            <Form.Group>
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type="tel"
                                    placeholder="+1234567890"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                            </Form.Group>
                            {loading && <Spinner animation="border" />}
                            <Button
                                className="mt-3"
                                variant="primary"
                                onClick={startMfaSetup}
                                disabled={!phoneNumber || loading}
                            >
                                Send Verification Code
                            </Button>
                        </Form>
                        {showReauthForm && (
                            <Modal show={showReauthForm} onHide={() => setShowReauthForm(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Reauthentication Required</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                value={reauthEmail}
                                                onChange={(e) => setReauthEmail(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                value={reauthPassword}
                                                onChange={(e) => setReauthPassword(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShowReauthForm(false)}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" onClick={reauthenticateUser}>
                                        Reauthenticate
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        )}
                        {verificationId && (
                            <>
                                <Form.Group className="mt-3">
                                    <Form.Label>Verification Code</Form.Label>
                                    <VerificationCodeInput
                                        length={6}
                                        onChange={(code) => setVerificationCode(code)}
                                        disabled={isVerifying}
                                    />
                                </Form.Group>
                                <Button
                                    className="mt-3"
                                    variant="success"
                                    onClick={verifyCode}
                                    disabled={verificationCode.length < 6 || isVerifying}
                                >
                                    Verify Code
                                </Button>
                            </>
                        )}
                    </>
                )}
            </div>
            <div id="recaptcha-container"></div>
        </div>
    );
};

export default PhoneVerification;
