import { useState, useCallback, useRef, useEffect, useContext } from "react";
import { AuthContext } from "../context/auth-context";
import { useRefreshToken } from './useRefreshToken'; // Adjust path as necessary

export const useHttpClient = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const activeHttpRequests = useRef([]);
    const refreshAccessToken = useRefreshToken();
    const [forceTokenRefresh, setForceTokenRefresh] = useState(false);  
    const auth = useContext(AuthContext);

    const sendRequest = useCallback(async (route, method = "GET", body = null, headers = {}) => {
        setIsLoading(true);
        const httpAbortCtrl = new AbortController();
        activeHttpRequests.current.push(httpAbortCtrl);

        let combinedHeaders = {
            ...headers,
            ...(auth.token ? { Authorization: "Bearer " + auth.token } : {}),
        };

        if (auth.currentOrganization && auth.currentOrganization.orgId) {
            console.log("Current Organization ID:", auth.currentOrganization.orgName);
            combinedHeaders["x-org-id"] = auth.currentOrganization.orgId;
        }
        
        try {
            let response = await fetch(process.env.REACT_APP_BACKEND_URL + route, {
                method,
                body,
                headers: combinedHeaders,
                signal: httpAbortCtrl.signal,
            });

            if (response.status === 401 || forceTokenRefresh) {
                console.log("Access token expired. Refreshing token...");
                try{
                    const newToken = await refreshAccessToken();
                    if (newToken) {
                        combinedHeaders.Authorization = "Bearer " + newToken;
                        response = await fetch(process.env.REACT_APP_BACKEND_URL + route, {
                            method,
                            body,
                            headers: combinedHeaders,
                            signal: httpAbortCtrl.signal,
                        });
                    }
                } catch (refreshError) {
                    console.error("Token refresh failed. Logging out...", refreshError);
                    //auth.logout();
                    window.location.href = "/";
                    throw new Error("Token refresh failed. User logged out.");
                }
            }

            if (response.status === 204) {
                return null; // No content to parse
            }
            
            const responseData = await response.json();
            activeHttpRequests.current = activeHttpRequests.current.filter(reqCtrl => reqCtrl !== httpAbortCtrl);

            if (!response.ok) {
                throw new Error(responseData.message);
            }

            setIsLoading(false);
            return { data: responseData, status: response.status };
        } catch (err) {
            setError(err.message);
            setIsLoading(false);
            throw err;
        }
    }, [refreshAccessToken, forceTokenRefresh, auth.currentOrganization, auth.token]);

    const clearError = () => setError(null);

    const simulateTokenExpiry = () => {
        const storedData = JSON.parse(localStorage.getItem("userData"));
        if (storedData) {
            storedData.token = "EXPIRED_TOKEN";
            localStorage.setItem("userData", JSON.stringify(storedData));
            setForceTokenRefresh(true);
        }
    };

    useEffect(() => {
        setForceTokenRefresh(false);
        return () => activeHttpRequests.current.forEach(abortCtrl => abortCtrl.abort());
    }, []);

    return { isLoading, error, sendRequest, clearError, simulateTokenExpiry };
};
