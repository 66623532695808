import React, { useState, useRef } from 'react';
import { Form } from 'react-bootstrap';

const VerificationCodeInput = ({ length, onChange, disabled }) => {
    const inputsRef = useRef([]);
    const [values, setValues] = useState(new Array(length).fill(''));

    const handleChange = (e, index) => {
        const val = e.target.value;
        if (!/^\d*$/.test(val) && val !== '') return;
        
        const newValues = [...values];
        newValues[index] = val;
        setValues(newValues);
    
        if (val !== '' && index < length - 1) {
            inputsRef.current[index + 1].focus();
        }

        const code = newValues.join('');
        onChange(code);
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            e.preventDefault();
            const newValues = [...values];
            if (newValues[index] !== '') {
                newValues[index] = '';
                setValues(newValues);
                onChange(newValues.join(''));
            } else if (index > 0) {
                inputsRef.current[index - 1].focus();
            }
        } else if (e.key === 'ArrowLeft' && index > 0) {
            inputsRef.current[index - 1].focus();
        } else if (e.key === 'ArrowRight' && index < length - 1) {
            inputsRef.current[index + 1].focus();
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text').slice(0, length);
        const pasteValues = pasteData.split('');
        const newValues = [...values];

        pasteValues.forEach((val, idx) => {
            if (/^\d$/.test(val) && idx < length) {
                newValues[idx] = val;
            }
        });

        setValues(newValues);
        onChange(newValues.join(''));

        const lastFilledIndex = pasteValues.length - 1;
        if (inputsRef.current[lastFilledIndex]) {
            inputsRef.current[lastFilledIndex].focus();
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
            {Array.from({ length }, (_, index) => (
                <Form.Control
                key={index}
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength={1}
                value={values[index]}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={handlePaste}
                ref={(el) => (inputsRef.current[index] = el)}
                style={{
                    width: '40px',
                    textAlign: 'center',
                    fontSize: '1.5rem',
                    padding: '0',
                    margin: '0',
                    lineHeight: '1.5rem',
                }}
                disabled={disabled}
                />
            ))}
        </div>
    );
};

export default VerificationCodeInput;
