import React, { useState, useEffect } from "react";
import { PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier } from "firebase/auth";
import { Modal, Button, Form, Spinner, Alert } from "react-bootstrap";
import VerificationCodeInput from "../components/VerificationCodeInput";

const MfaVerificationModal = ({ auth, resolver, onSuccess, onError, show, onHide }) => {
    const [verificationCode, setVerificationCode] = useState("");
    const [verificationId, setVerificationId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const sendVerificationCode = async () => {
            setIsLoading(true);
            try {
                const hint = resolver.hints[0];

                if (!window.recaptchaVerifier) {
                    window.recaptchaVerifier = new RecaptchaVerifier(
                        auth,
                        'recaptcha-container',
                        {
                            size: 'invisible',
                            callback: (response) => {
                                console.log('reCAPTCHA solved');
                            },
                        }
                    );
                }

                const phoneAuthProvider = new PhoneAuthProvider(auth);
                const phoneInfoOptions = {
                    multiFactorHint: hint,
                    session: resolver.session,
                };

                const verificationId = await phoneAuthProvider.verifyPhoneNumber(
                    phoneInfoOptions,
                    window.recaptchaVerifier
                )

                setVerificationId(verificationId);
            } catch (error) {
                console.error('Error during MFA code sending:', error);
                setErrorMessage('Error sending verification code. Please try again.');
                if (onError) onError(error);
            } finally {
                setIsLoading(false);
            }
        };

        sendVerificationCode();
    }, [auth, resolver, onError]);

    const handleVerifyCode = async (event) => {
        event.preventDefault();
        try {
            const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
            const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential);

            const userCredential = await resolver.resolveSignIn(multiFactorAssertion);

            if (onSuccess) onSuccess(userCredential);
        } catch (error) {
            console.error('Error verifying code:', error);
            if (onError) onError(error);
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Multi-Factor Authentication</Modal.Title>
            </Modal.Header>
            <div id="recaptcha-container"></div>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            { isLoading ? (
                <>
                <Modal.Body>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minHeight: '150px',
                    }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" type="button" onClick={onHide}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </>
            ) : (
                <Form onSubmit={handleVerifyCode}>
            <Modal.Body>
                <p>A verification code has been sent to your phone. Please enter it below</p>
                    <VerificationCodeInput
                        length={6}
                        onChange={(code) => setVerificationCode(code)}
                    />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" type="button" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" type="submit" onClick={handleVerifyCode}
                    disabled={verificationCode.length < 6}
                >
                    Verify Code
                </Button>
            </Modal.Footer>
                </Form>
            )}
        </Modal>
    );
};

export default MfaVerificationModal;