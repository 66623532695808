import React, { useEffect, useState, useContext } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { Card, Row, Col, Modal } from "react-bootstrap";
import { AuthContext } from "../../shared/context/auth-context";

const AvatarSelection = ({ value, onChange, onBlur, error, touched }) => {
    const authContext = useContext(AuthContext);
    const { sendRequest } = useHttpClient();
    const [avatars, setAvatars] = useState([]);
    const [selectedAvatar, setSelectedAvatar] = useState(value?.avatarId || null);
    const [selectedLook, setSelectedLook] = useState(value?.lookId || null);
    const [showModal, setShowModal] = useState(false);
    const [looks, setLooks] = useState([]);
    const [hasBlurred, setHasBlurred] = useState(false);

    const handleBlur = () => {
        if (!selectedAvatar) {
            setHasBlurred(true);
            if (onBlur) {
                onBlur();
            }
        }
    };

    const handleCardClick = (avatar) => {
        const firstLook = avatar.scenes[0] || null;
        
        setSelectedAvatar(avatar);
        setSelectedLook(firstLook);
        setLooks(avatar.scenes);
        setShowModal(avatar.scenes.length > 1);

        onChange({
            avatarId: avatar.id,
            lookId: firstLook?.id || null,
            thumbnail: avatar.image_url,
        });
        setHasBlurred(false);
    };

    const handleLookClick = (look) => {
        setSelectedLook(look);
        onChange({ avatarId: selectedAvatar.id, lookId: look.id, thumbnail: look.image_url });
        //setShowModal(false);
    };


    useEffect(() => {
        const fetchAvatars = async () => {
            try {
                const response = await sendRequest("/api/avatars", "GET", null, {
                    Authorization: "Bearer " + authContext.token,
                });
                setAvatars(response.data);
            } catch (err) {
                console.error("Error fetching avatars:", err);
            }
        };

        fetchAvatars();
    }, [sendRequest, authContext.token]);


    return (
        <React.Fragment>
        <div style={{ overflowX: "auto", whiteSpace: "nowrap" }} onBlur={handleBlur}>
            <p>Avatar</p>
            <Row>
                {avatars.map((avatar) => (
                    <Col key={avatar.id} xs={3} sm={3} md={2} style={{ display: "inline-block", float: "none" }}>
                        <Card
                            className={selectedAvatar === avatar ? "border-primary" : ""}
                            onClick={() => handleCardClick(avatar)}
                            style={{ cursor: "pointer", margin: "5px", width: "100%" }}
                        >
                            <Card.Img
                                variant="top"
                                src={selectedAvatar?.id === avatar.id && value.thumbnail ? value.thumbnail : avatar.image_url} />
                            <Card.Body>
                                <Card.Title style={{ fontSize: "0.8rem", textAlign: "center" }}>
                                    {avatar.avatar_name}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
        {touched && hasBlurred && error && <div className="text-danger">{error}</div>}
        <Modal show={showModal} onHide={() => setShowModal(false)} size="md">
            <Modal.Header closeButton>
                <Modal.Title>{`Select a scene for ${selectedAvatar?.avatar_name}` || "Selected Avatar"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {looks.length > 0 ? (
                    <div style = {{
                        maxWidth: "100%",
                        maxHeight: "400px",
                        overflowY: "auto",
                        overflowX: "hidden",
                        margin: "0 auto"
                    }}>
                        <Row xs={1} md={2} lg={2} className="g-4">
                            {looks.map((look) => (
                                <Col key={look.id}>
                                    <Card 
                                        className={`card-clickable ${selectedLook === look ? "border-primary" : ""}`}
                                        onClick={() => handleLookClick(look)}
                                        style={{ 
                                            width: "13rem",
                                            borderWidth: selectedLook === look ? "2px" : "1px",
                                            }}>
                                            <Card.Img src={look.image_url} />
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                ): (
                    <p>No looks available for this avatar</p>
                )}
            </Modal.Body>
        </Modal>
        </React.Fragment>
    );
};

export default AvatarSelection;
