import React, { useState, useContext, useEffect } from 'react';
import { auth } from '../../config/firebase';
import {
  RecaptchaVerifier,
  PhoneAuthProvider,
  multiFactor,
  PhoneMultiFactorGenerator,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification
} from 'firebase/auth';
import { Button, Alert, Form, Spinner, Modal, Container, Row, Col, Card } from 'react-bootstrap';
import ToastError from '../../shared/elements/ToastError';
import VerificationCodeInput from '../components/VerificationCodeInput';
import Particles from '../../components/Particles';
import { AuthContext } from '../../shared/context/auth-context';

const MfaSetup = () => {
  const authContext = useContext(AuthContext);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [mfaSetupComplete, setMfaSetupComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [reauthEmail, setReauthEmail] = useState('');
  const [reauthPassword, setReauthPassword] = useState('');
  const [showReauthForm, setShowReauthForm] = useState(false);

  const [isEmailVerified, setIsEmailVerified] = useState(auth.currentUser.emailVerified);
  const [emailSent, setEmailSent] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  useEffect(() => {
    const interval = setInterval(async () => {
      await auth.currentUser.reload();
      setIsEmailVerified(auth.currentUser.emailVerified);
      if (auth.currentUser.emailVerified) {
        clearInterval(interval);
      }
    }, 5000); // Check every 5 seconds
  
    return () => clearInterval(interval);
  }, []);
  

  const reauthenticateUser = async () => {
    try {
      setLoading(true);
      const credential = EmailAuthProvider.credential(reauthEmail, reauthPassword);
      await reauthenticateWithCredential(auth.currentUser, credential);
      console.log('Reauthentication successful.');
      setShowReauthForm(false);

      await startMfaSetup();
    } catch (error) {
      console.error('Error during reauthentication:', error);
      setErrorMessage('Failed to reauthenticate. Please try again or logout and log back in.');
      setLoading(false);
    }
  };

  const setupRecaptcha = () => {
    if (process.env.REACT_APP_ENV === 'development') {
      auth.settings.appVerificationDisabledForTesting = true;
    }

    try {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        delete window.recaptchaVerifier;

        const recaptchaContainer = document.getElementById('recaptcha-container');
        if (recaptchaContainer) {
          recaptchaContainer.innerHTML = '';
        }
      }

      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        'recaptcha-container',
        {
          size: 'invisible',
          callback: () => console.log('Recaptcha solved'),
        },
      );
    } catch (error) {
      console.error('Error initializing RecaptchaVerifier:', error);
      throw error;
    }
  };

  const startMfaSetup = async () => {
    try {
      setupRecaptcha();

      const appVerifier = window.recaptchaVerifier;
      const phoneAuthProvider = new PhoneAuthProvider(auth);

      setLoading(true);

      const multiFactorSession = await multiFactor(auth.currentUser).getSession();

      const phoneInfoOptions = {
        phoneNumber: phoneNumber,
        session: multiFactorSession,
      };

      const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, appVerifier);
      setVerificationId(verificationId);
      setLoading(false);
    } catch (error) {
      console.error('Error during MFA setup:', error);
      if (error.code === 'auth/requires-recent-login') {
        setErrorMessage('Please reauthenticate to enable MFA.');
        setShowReauthForm(true);
      } else {
        setErrorMessage('Failed to send verification code. Please try again.');
      }
      setLoading(false);
    }
  };

  const verifyCode = async () => {
    if (!verificationId || !verificationCode) {
      console.error('Verification ID or code is missing. Aborting.');
      return;
    }

    try {
      setIsVerifying(true);
      const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential);

      await multiFactor(auth.currentUser).enroll(multiFactorAssertion, 'Phone Number');
      setMfaSetupComplete(true);

      await authContext.refreshUserData();
    } catch (error) {
      console.error('Error during MFA verification:', error);
      setErrorMessage('Invalid verification code. Please try again.');
    } finally {
      setIsVerifying(false);
    }
  };

  const handleSendVerificationEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      setEmailSent(true);
    } catch (error) {
      console.error('Error sending email verification:', error);
      setEmailErrorMessage('Failed to send verification email. Please try again later.');
    }
  };  

  return (
    <div className="mfa-setup-page" style={{ position: 'relative' }}>
      <Particles
        className="absolute inset-0 z-0"
        quantity={100}
        ease={100}
        color="#1c004b"
        enable={true}
      />
      <Container>
        <Row
            className="align-items-center justify-content-center"
            style={{ height: '100vh'}}
        >
          <Col xs={12} md={6}>
          <Card
            style={{
                width: '22rem',
                margin: '0 auto',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
            }}
          >
            <Card.Body>
            <h4>Multi-Factor Authentication</h4>
            <p>Your organization requires Multi-Factor Authentication.</p>
            {!isEmailVerified ? (
                <>
                <p>Please verify your email address to continue.</p>
                {emailSent ? (
                    <Alert variant = "success">
                        Verification email sent successfully! Please check your inbox.
                    </Alert>
                ) : (
                    <Button variant="primary" onClick={handleSendVerificationEmail}>
                        Send Verification Email
                    </Button>
                )}
                {emailErrorMessage && (
                    <Alert variant="danger" style={{ marginTop: '1rem' }}>
                        {emailErrorMessage}
                    </Alert>
                )}
                <p style={{ marginTop: '1rem'}}>
                    Once you have verified your email, this page will automatically update.
                </p>
                <Button
                    className="mt-3"
                    variant="secondary"
                    onClick={() => authContext.logout()}
                >
                    Exit
                </Button>
                </>
            ) : mfaSetupComplete ? (
              <Alert variant="success">SMS Multi-Factor Authentication is enabled.</Alert>
            ) : (
              <>
                <ToastError
                  show={!!errorMessage}
                  message={errorMessage}
                  onClose={() => setErrorMessage('')}
                />
                <Form
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent default form submission
                      if (!verificationId) {
                        // If no verificationId, trigger phone number setup
                        if (phoneNumber) startMfaSetup();
                      } else {
                        // If verificationId exists, trigger verification
                        if (verificationCode.length === 6) verifyCode();
                      }
                    }
                  }}
                >
                  {!verificationId && (
                    <>
                      <Form.Group>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="tel"
                          placeholder="+1234567890"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </Form.Group>
                      {loading && <Spinner animation="border" />}
                      <Button
                        className="mt-3"
                        variant="primary"
                        onClick={startMfaSetup}
                        disabled={!phoneNumber || loading}
                      >
                        Send Verification Code
                      </Button>
                      <Button
                        className="mt-3"
                        variant="secondary"
                        onClick={() => authContext.logout()}
                        >
                        Exit
                        </Button>
                    </>
                  )}
                  {verificationId && (
                    <>
                    <Alert variant = "success">
                        Verification code sent successfully!
                    </Alert>
                      <Form.Group className="mt-3">
                        <Form.Label>Verification Code</Form.Label>
                        <VerificationCodeInput
                          length={6}
                          onChange={(code) => setVerificationCode(code)}
                          disabled={isVerifying}
                        />
                      </Form.Group>
                      <Button
                        className="mt-3"
                        variant="success"
                        onClick={verifyCode}
                        disabled={verificationCode.length < 6 || isVerifying}
                      >
                        Verify Code
                      </Button>
                    </>
                  )}
                </Form>
                {showReauthForm && (
                  <Modal show={showReauthForm} onHide={() => setShowReauthForm(false)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Reauthentication Required</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            value={reauthEmail}
                            onChange={(e) => setReauthEmail(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            value={reauthPassword}
                            onChange={(e) => setReauthPassword(e.target.value)}
                          />
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => setShowReauthForm(false)}>
                        Cancel
                      </Button>
                      <Button variant="primary" onClick={reauthenticateUser}>
                        Reauthenticate
                      </Button>
                    </Modal.Footer>
                  </Modal>
                )}
                <div id="recaptcha-container"></div>
              </>
            )}
            </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default MfaSetup;
