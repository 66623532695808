import React, { useState, useContext } from "react";
import { Card, Row, Col, Container, Placeholder, Modal, Button, Form, /*Carousel*/ } from "react-bootstrap";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import ToastError from "../../shared/elements/ToastError";

const AvatarList = ({ loadedAvatarInfo, onShowToast }) => {
    const { sendRequest, error, clearError } = useHttpClient();
    const auth = useContext(AuthContext);
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showSceneModal, setShowSceneModal] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [looks, setLooks] = useState([]);
    const [isImageValid, setIsImageValid] = useState(false);
    const [imageUrl, setImageUrl] = useState("");

    const handleCardClick = async (avatar) => {
        //if (!auth.isSuperAdmin) return; // Prevent click if not super admin

        setSelectedAvatar(avatar);
        setShowModal(true);

        try {
            if(auth.isSuperAdmin) {
            const response = await sendRequest(
                "/api/organizations",
                "GET",
                null,
                {
                    Authorization: "Bearer " + auth.token,
                }
            );
            setOrganizations(response.data);
        }
            console.log(avatar.scenes);
            setLooks(avatar.scenes);
        } catch (error) {
            console.error("Failed to load", error);
        }
    };

    const handleOrgChange = (e) => {
        setSelectedOrg(e.target.value);
    };

    const handleAvatarAction = async (action) => {
        if (!selectedOrg) {
            return;
        }

        setIsLoading(true);
        try {
            const endpoint = action === "add"
                ? "/api/organizations/add-avatar"
                : "/api/organizations/remove-avatar";
            await sendRequest(
                endpoint,
                "POST",
                JSON.stringify({ orgId: selectedOrg, avatarId: selectedAvatar.id }),
                {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + auth.token,
                }
            );
            onShowToast(); // Trigger the toast in the parent component
            setShowModal(false); // Close the modal on success
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAvatarIdBlur = (event) => {
        const avatarId = event.target.value;
        const fullUrl = `https://files2.heygen.ai/avatar/v3/${avatarId}/full/2.2/preview_target.webp`;
        const halfUrl = `https://files2.heygen.ai/avatar/v3/${avatarId}/half/2.2/preview_target.webp`;

        // Create a new Image object to check if the image is valid
        const img = new Image();
        img.onload = () => {
            setImageUrl(fullUrl);
            setIsImageValid(true);
        };
        img.onerror = () => {
            const fallbackImg = new Image();
            fallbackImg.onload = () => {
                setImageUrl(halfUrl);
                setIsImageValid(true);
            }
            fallbackImg.onerror = () => {
                setImageUrl("");
                setIsImageValid(false);
            }
            fallbackImg.src = halfUrl; // This will trigger the onload/onerror events
        };
        img.src = fullUrl;  // This will trigger the onload/onerror events
    };

    if (!loadedAvatarInfo) {
        return (
            <Container>
                <Card>
                    <Card.Body>
                        <Placeholder as={Card.Title} animation="glow">
                            <Placeholder xs={6} />
                        </Placeholder>
                        <Placeholder as={Card.Text} animation="glow">
                            <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                            <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                            <Placeholder xs={8} />
                        </Placeholder>
                    </Card.Body>
                </Card>
            </Container>
        );
    }

    return (
        <Container>
            <ToastError show={!!error} message={error} onClose={clearError} />
            <Row xs={1} md={2} lg={3} className="g-4">
                {loadedAvatarInfo.map((avatar) => (
                    <Col key={avatar.id}>
                        <Card
                            className={`card-clickable ${selectedAvatar === avatar ? "card-selected" : ""}`}
                            onClick={() => handleCardClick(avatar)}
                            //style={auth.isSuperAdmin ? {} : { pointerEvents: "none" }} // Disable pointer events for non-super admins
                        >
                            {/*<Carousel>
                                {avatar.scenes.map((scene) => (
                                    <Carousel.Item key={scene.id}>
                                        <img
                                            className="d-block w-100"
                                            src={scene.image_url}
                                            alt={scene.id}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>*/}
                            <Card.Img
                                variant="top"
                                src={avatar.image_url}
                                alt={avatar.avatar_name}
                            />
                            <Card.Body>
                                <Card.Title>{avatar.avatar_name}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedAvatar?.avatar_name || "Selected Avatar"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {looks.length > 0 ? (
                        <div style = {{
                            maxWidth: "100%",
                            maxHeight: "400px",
                            overflowY: "auto",
                            overflowX: "hidden",
                            margin: "0 auto"
                        }}>
                        <Row xs={1} md={2} lg={2} className="g-4">
                            {looks.map((look) => (
                                <Col key={look.id}>
                                    <Card style={{ width: "13rem" }}>
                                        <Card.Img src={look.image_url} />
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        </div>
                    ) : (
                        <p>No looks available.</p>
                    )}
                    {auth.isSuperAdmin && (organizations.length > 0 ? (
                        <Form>
                            <Form.Group controlId="orgSelect">
                                <Form.Label>Select Organization</Form.Label>
                                <Form.Control as="select" value={selectedOrg} onChange={handleOrgChange}>
                                    <option value="">Select an organization</option>
                                    {organizations.map((org) => (
                                        <option key={org.orgId} value={org.orgId}>
                                            {org.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    ) : (
                        <p>No organizations available or failed to load.</p>
                    ))}
                </Modal.Body>
                { auth.isSuperAdmin && (
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => handleAvatarAction("add")}
                        disabled={isLoading || !organizations.length}
                    >
                        {isLoading ? "Adding..." : "Add Avatar To Org"}
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => handleAvatarAction("remove")}
                        disabled={isLoading || !organizations.length}
                    >
                        {isLoading ? "Removing..." : "Remove Avatar"}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={() => setShowSceneModal(true)}
                        disabled={isLoading}
                    >
                        Add Look
                    </Button>
                </Modal.Footer>
                )}
            </Modal>
            <Modal show={showSceneModal} onHide={() => setShowSceneModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Look to {selectedAvatar?.avatar_name || "selected avatar"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{ sceneId: "", orientation: "horizontal" }}
                        onSubmit={async (values) => {
                            try {
                                await sendRequest(
                                    '/api/avatars/add-scene',
                                    'POST',
                                    JSON.stringify({ sceneId: values.sceneId, avatarId: selectedAvatar.id, orientation: values.orientation }),
                                    {
                                        'Content-Type': 'application/json',
                                        Authorization: 'Bearer ' + auth.token,
                                    }
                                );
                                setImageUrl("");
                                setShowSceneModal(false);
                            } catch (error) {
                                console.error('Failed to add scene:', error);
                            }
                        }}
                    >
                        <FormikForm>
                            <Form.Group controlId="sceneId">
                                <Form.Label>Scene ID</Form.Label>
                                <Field
                                    type="text"
                                    name="sceneId"
                                    className="form-control"
                                    onBlur={handleAvatarIdBlur}
                                />
                                <ErrorMessage name="sceneId" component="div" className="text-danger" />
                            </Form.Group>
                            <Form.Group as ={Row} controlId="orientation">
                                <Form.Label>Orientation</Form.Label>
                                <Field as="select" name="orientation">
                                    <option value="horizontal">Horizontal</option>
                                    <option value="vertical">Vertical</option>
                                </Field>
                            </Form.Group>
                            {isImageValid && (
                                <Row className="mb-3" style={{ textAlign: "center" }}>
                                    <img
                                        src={imageUrl}
                                        alt="Avatar Preview"
                                        style={{ width: "100%", maxHeight: "200px", objectFit: "contain" }}
                                    />
                                </Row>
                            )}
                            <Button type="submit" disabled={!isImageValid}>Add Scene</Button>
                        </FormikForm>
                    </Formik>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default AvatarList;
