import React, { useEffect, useState, useContext } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { Card, Spinner, Row, Col } from "react-bootstrap";
import ToastError from "../../shared/elements/ToastError";
import AvatarViewer from "../../workspace/pages/AvatarViewer";

const Metrics = () => {
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const authContext = useContext(AuthContext);
    const [metrics, setMetrics] = useState(null);


    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const response = await sendRequest(
                    `/api/organization/metrics`,
                    "GET",
                    null,
                    {
                        Authorization: "Bearer " + authContext.token,
                    }
                );
                setMetrics(response.data);
            } catch (err) {
                console.error("Error fetching metrics:", err);
            }
        };

        fetchMetrics();
    }, [sendRequest, authContext.token]);

    return (
        <div>
            <ToastError show={!!error} message={error} onClose={clearError} />
            {isLoading && <Spinner animation="border" />}
            {metrics && (
                <React.Fragment>
                <Row>
                    {/*<Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Available Avatars</Card.Title>
                                <Card.Text>{metrics.avatars}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>*/}
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Total Videos</Card.Title>
                                <Card.Text>{metrics.videos}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>Total Scripts</Card.Title>
                                <Card.Text>{metrics.scripts}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <h2>Available Avatars</h2>
                    <AvatarViewer />
                </Row>
                </React.Fragment>
            )}
        </div>
    );
};

export default Metrics;
